<template>
    <router-view :key="route.params.id"></router-view>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["route"])
    }
};
</script>
